import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/feedback/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          addData(ctx,queryParams){
              //var data = JSON.stringify(queryParams);
              
              return new Promise((resolve, reject) => {
                axios
                  .post('/feedback/v1/add',queryParams.formData)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
  
          },
          updateData(ctx,queryParams){
           
             // var data = JSON.stringify(queryParams.formData);
              
              return new Promise((resolve, reject) => {
                axios
                  .put('/feedback/v1/update/'+queryParams.data.id,queryParams.formData,{
                    headers:{
                      'Content-Type': 'multipart/form-data',
                      'dataType':'json'
                    }
                  })
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })
  
          },
          deleteData(ctx,id){
            
              
            return new Promise((resolve, reject) => {
              axios
                .delete('/feedback/v1/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
  
        }
    }
}